import { type FormEvent } from 'react';
import { Description, Field, Radio, RadioGroup } from '@headlessui/react';
import GiftIcon from '@stageplus/icons/react/gift';
import HeartIcon from '@stageplus/icons/react/heart';
import InfoIcon from '@stageplus/icons/react/info';
import SmallChevronIcon from '@stageplus/icons/react/small-chevron';
import clsx from 'clsx';
import Image from 'next/image';
import type { StaticImageData } from 'next/image';
import heroGiftCardImage from 'public/images/christmas2024/hero-gift-card.avif';
import heroSubscriptionImage from 'public/images/christmas2024/hero-subscription.avif';
import ornamentImage from 'public/images/christmas2024/ornament.svg';
import patternRingsImage from 'public/images/christmas2024/pattern-rings.svg';
import patternTopoImage from 'public/images/christmas2024/pattern-topo.svg';
import { TextButton } from 'src/components/buttons/text-button';
import { GiftCardsBadge } from 'src/components/gift-cards/gift-cards-badge';
import useTranslate from 'src/hooks/use-translate';
import type { TranslationKeyCommon } from 'src/types';

export enum PurchaseOptionType {
  AnnualSubscription = 'AnnualSubscription',
  GiftCard = 'GiftCard',
}

type PurchaseOption = {
  type: PurchaseOptionType;
  title: TranslationKeyCommon;
  gift: TranslationKeyCommon;
  feature: TranslationKeyCommon;
  description: TranslationKeyCommon;
  featureIcon: typeof HeartIcon;
  background: StaticImageData;
};

const purchaseOptions: PurchaseOption[] = [
  {
    type: PurchaseOptionType.AnnualSubscription,
    title: 'gift_cards__options_annual_subscription_title',
    gift: 'gift_cards__options_annual_subscription_gift',
    feature: 'gift_cards__options_annual_subscription_feature_1',
    description: 'gift_cards__options_annual_subscription_description',
    featureIcon: HeartIcon,
    background: patternRingsImage as StaticImageData,
  },
  {
    type: PurchaseOptionType.GiftCard,
    title: 'gift_cards__options_gift_card_title',
    gift: 'gift_cards__options_gift_card_gift',
    feature: 'gift_cards__options_gift_card_feature_1',
    description: 'gift_cards__options_gift_card_description',
    featureIcon: GiftIcon,
    background: patternTopoImage as StaticImageData,
  },
];

function PurchaseOptionRadioButton({
  option,
  focus,
  checked,
  disabled,
  disabledText,
}: {
  option: PurchaseOption;
  focus: boolean;
  checked: boolean;
  disabled: boolean;
  disabledText?: string;
}) {
  const t = useTranslate();
  const Icon = option.featureIcon;
  return (
    <div
      className={clsx(
        'relative rounded-xl border bg-surface-600 bg-[position:center_-30%] bg-no-repeat p-3 md:gap-10 lg:p-6',
        checked ? 'border-[6px] border-campaignAccent' : 'm-[5px] border-surface-500',
        focus && 'outline outline-2 outline-offset-4 outline-focusOutline',
      )}
      style={{ backgroundImage: disabled ? undefined : `url('${option.background.src}')` }}
    >
      {disabled && (
        <Description className="absolute inset-0 flex flex-col items-center justify-center gap-2 text-balance p-4 text-center typo-caption-1">
          <InfoIcon className="rounded-full bg-textPrimary text-pageBackground" />
          <span>{disabledText}</span>
        </Description>
      )}
      <div className={clsx('flex flex-col gap-6 lg:gap-10', disabled && 'pointer-events-none opacity-[2%]')}>
        <div className="flex flex-col gap-1">
          <p className="typo-title-3">{t(option.title)}</p>
          <p className="text-campaignAccent typo-caption-1">{t(option.gift)}</p>
        </div>
        <p className="flex items-center gap-2 italic text-campaignAccent typo-caption-1 japanese:not-italic">
          <Icon className="h-4" />
          <span>{t(option.feature)}</span>
        </p>
        <p className="text-textTertiary typo-caption-2">{t(option.description)}</p>
      </div>
    </div>
  );
}

function PurchaseOptionSelector({
  selectedOption,
  onChange,
  disableAnnualSubscription,
}: {
  selectedOption: PurchaseOptionType;
  onChange: (selectedOption: PurchaseOptionType) => void;
  disableAnnualSubscription: boolean;
}) {
  const t = useTranslate();
  return (
    <RadioGroup value={selectedOption} onChange={onChange} className="flex flex-col gap-3">
      {purchaseOptions?.map((option) => {
        const isDisabled = disableAnnualSubscription && option.type === PurchaseOptionType.AnnualSubscription;
        return (
          <Field key={option.type}>
            <Radio
              value={option.type}
              className={clsx('focus-visible:outline-none', !disableAnnualSubscription && 'cursor-pointer')}
              disabled={isDisabled}
            >
              {({ checked, focus }) => (
                <PurchaseOptionRadioButton
                  option={option}
                  focus={focus}
                  checked={checked}
                  disabled={isDisabled}
                  disabledText={t('gift_cards__options_annual_subscription_disabled')}
                />
              )}
            </Radio>
          </Field>
        );
      })}
    </RadioGroup>
  );
}

function PurchaseOptionIllustration({ selectedOption }: { selectedOption: PurchaseOptionType }) {
  return (
    <div
      className="relative min-h-[80vw] w-full overflow-hidden rounded-3xl border border-white/10 bg-cover bg-center bg-no-repeat md:min-h-[60vw] lg:min-h-full"
      aria-hidden
      style={{ backgroundImage: `url('${heroSubscriptionImage.src}')` }}
    >
      <div
        className={clsx(
          'absolute inset-0 bg-cover bg-center bg-no-repeat transition-opacity',
          selectedOption === PurchaseOptionType.AnnualSubscription && 'opacity-0',
        )}
        style={{ backgroundImage: `url('${heroGiftCardImage.src}')` }}
      />
    </div>
  );
}

type GiftCardsPurchaseHeroProps = {
  selectedOption: PurchaseOptionType;
  onChange: (selectedOption: PurchaseOptionType) => void;
  onSubmit: () => void;
  disableAnnualSubscription: boolean;
};

export function GiftCardsPurchaseHero({
  selectedOption,
  onChange,
  onSubmit,
  disableAnnualSubscription,
}: GiftCardsPurchaseHeroProps) {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  const t = useTranslate();

  return (
    <div className="flex flex-col gap-8">
      <h1 className="mx-auto flex max-w-[50rem] flex-col items-center gap-5 text-balance text-center">
        <GiftCardsBadge>{t('gift_cards__pretitle')}</GiftCardsBadge>
        <Image src={ornamentImage as StaticImageData} width={100} height={10} alt="" className="" unoptimized />
        <span className="block typo-large-title">{t('gift_cards__title')}</span>
      </h1>
      <form id="purchase" className="pt-8" onSubmit={handleSubmit}>
        <div className="mx-auto flex w-full flex-col gap-14 lg:flex-row">
          <PurchaseOptionIllustration selectedOption={selectedOption} />
          <div className="flex grow flex-col justify-between gap-8 lg:min-w-[28rem]">
            <div className="flex flex-col gap-1">
              <h2 className="text-textPrimary typo-title-2">{t('gift_cards__options_heading')}</h2>
              <p className="text-textTertiary typo-body-2">{t('gift_cards__options_subheading')}</p>
            </div>
            <div className="-mx-1">
              <PurchaseOptionSelector
                selectedOption={selectedOption}
                onChange={onChange}
                disableAnnualSubscription={disableAnnualSubscription}
              />
            </div>
            <TextButton type="submit" data-test="signup-cta-button">
              {t('gift_cards__options_submit_button_label')}
              <span className="-mr-2">
                <SmallChevronIcon className="inline-block" aria-hidden />
              </span>
            </TextButton>
          </div>
        </div>
      </form>
    </div>
  );
}
