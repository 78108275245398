import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { PurchaseOptionType } from 'src/components/gift-cards/gift-cards-purchase-hero';
import useGeoInfo from 'src/hooks/use-geo-info';
import useSdk, { ProductsStripeQuery } from 'src/hooks/use-sdk';
import useTicketStatus from 'src/hooks/use-ticket-status';

/**
 * Hook that manages status and behavior of the Christmas 2024 campaign landing
 * page (future gift cards page)
 */
export function useGiftCards({ products }: { products: ProductsStripeQuery }) {
  // Every time the user visits this page, we should initiate a new checkout
  // session. The persisted purchaseID is used to query the purchase status on
  // the "thank you" page to which Stripe redirects the user after successful or
  // failing checkout attempt and we use it to query the said status – in order
  // to avoid querying the previous checkout session, we always delete the
  // previous purchase id on the visit to the landing page that initiates new
  // checkout session.
  useEffect(() => {
    // Clean the session storage if we land on the "purchase ticket" page
    sessionStorage.giftPurchaseId = null;
  }, []);

  const sdk = useSdk();
  const router = useRouter();
  const { data: geoInfo } = useGeoInfo();
  const { isValid: userHasSubscription, isMonthly: userHasMonthlySubscription } = useTicketStatus();

  // Only users without a subscription or with a monthly subscription are able
  // to purchase a yearly subscription
  const disableAnnualSubscription = userHasSubscription && !userHasMonthlySubscription;

  const [selectedOption, setSelectedOption] = useState<PurchaseOptionType>(PurchaseOptionType.AnnualSubscription);

  // Preselect the Gift Card option instead of the default Annual Subscription
  // (/gift-cards#gift)
  useEffect(() => {
    const shouldPreselectGift = typeof window !== 'undefined' && window.location.hash === '#gift';
    if (shouldPreselectGift || disableAnnualSubscription) {
      setSelectedOption(PurchaseOptionType.GiftCard);
    }
  }, [disableAnnualSubscription]);

  // When the use chooses the annual subscription, we redirect them to the Tickets
  // page, where they follow the normal subscription flow
  const handleSubscriptionSubmit = useCallback(async () => {
    await router.push(
      userHasSubscription && userHasMonthlySubscription
        ? '/settings/subscription/change'
        : '/account/signup?redirect=%2Fgift-card-subscription',
    );
  }, [router, userHasSubscription, userHasMonthlySubscription]);

  // When user chooses to purchase a gift code, we initiate a new Stripe checkout session using
  // previously preloaded product object, selecting the currency of the checkout based on the
  // user location and providing a priceId as the argument to start Stripe checkout.
  // We provide a callback URL where Stripe redirects the user after successful checkout. The failed
  // payment states are handled by Stripe itself (errors are shown on the Stripe Checkout page).
  // Note, that since the user navigates away from our domain, we must persist the purchaseID
  // in order to query the status of the purchase later (on /thank-you page).
  const handleGiftCardSubmit = useCallback(async () => {
    const eTicketProduct = products.productsStripe.find(({ id }) => id === 'e_ticket');
    const price =
      eTicketProduct &&
      geoInfo?.currencyCode &&
      eTicketProduct.prices?.find((price) => price.currency === geoInfo?.currencyCode);

    if (price?.id === undefined) {
      return;
    }

    const { setupProductPurchase } = await sdk.setupProductPurchase({
      input: {
        priceId: price?.id,
        successUrl: `${location.origin}/thank-you`,
      },
    });

    // Store purchaseId
    if (setupProductPurchase?.result?.purchaseId) {
      sessionStorage.giftPurchaseId = setupProductPurchase?.result?.purchaseId;
    }

    // Redirect to the URL returned by the backend
    // Not using the Next router because it might be outside of the Stage+ app
    if (setupProductPurchase?.result?.url) {
      window.location.href = setupProductPurchase.result.url;
    }
  }, [geoInfo, products, sdk]);

  const onSubmit = useCallback(async () => {
    await (selectedOption === PurchaseOptionType.AnnualSubscription
      ? handleSubscriptionSubmit()
      : handleGiftCardSubmit());
  }, [selectedOption, handleSubscriptionSubmit, handleGiftCardSubmit]);

  const onChangeOption = (option: PurchaseOptionType) => {
    setSelectedOption(option);
  };

  return { selectedOption, disableAnnualSubscription, onSubmit, onChangeOption };
}
